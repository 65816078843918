import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12V12.101C23.2372 13.3636 24 15.0927 24 17C24 20.866 20.866 24 17 24C15.0924 24 13.3631 23.237 12.1005 21.9995C12.067 21.9998 12.0335 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM10.6736 14H9.45406C9.31728 13.3285 9.25 12.6619 9.25 12C9.25 11.3381 9.31727 10.6715 9.45406 10H14.5459C14.5741 10.1383 14.5994 10.2765 14.6217 10.4144C12.8811 11.0431 11.4612 12.3422 10.6736 14ZM9.77235 19.6858C8.96947 18.4703 8.35229 17.2414 7.92943 16H5.07026C6.09237 17.7669 7.77088 19.1067 9.77235 19.6858ZM4.25203 14H7.41942C7.3069 13.3366 7.25 12.6699 7.25 12C7.25 11.3301 7.3069 10.6634 7.41942 10H4.25204C4.08751 10.6392 4 11.3094 4 12C4 12.6906 4.08751 13.3608 4.25203 14ZM5.07026 8H7.92942C8.35228 6.75863 8.96945 5.52974 9.77233 4.31426C7.77087 4.89335 6.09237 6.23307 5.07026 8ZM18.9297 8L16.0706 8C15.6477 6.75863 15.0305 5.52974 14.2277 4.31426C16.2291 4.89335 17.9076 6.23307 18.9297 8ZM10.0632 8C10.5141 6.88656 11.1565 5.75939 12 4.61745C12.8435 5.75939 13.4859 6.88656 13.9368 8H10.0632ZM17 22C19.7614 22 22 19.7614 22 17C22 14.2386 19.7614 12 17 12C14.2386 12 12 14.2386 12 17C12 19.7614 14.2386 22 17 22ZM18 14V17H20V19H16V14H18Z"
      />
    </svg>
  );
};

export default Icon;
