import { CalendarIntegration } from "@practice/sdk";

import { GcalType } from "@lib/data/schemas/integrations";

export const getGcalAccountEmail = (gcal: GcalType): string => {
  return (gcal.calendars.find((cal) => cal.primary) || gcal.calendars[0]).id;
};

export const getAllCalendarAccounts = (
  integrations: CalendarIntegration[],
  memberId?: string
) => {
  if (!integrations.length) return { accounts: [], defaultWriteCalendar: null };

  const defaultAccount =
    integrations.find((integration) => integration.memberId === memberId) ??
    integrations[0];

  const defaultCalendar =
    defaultAccount.calendars.find((cal) => cal.isDefaultCalendar) ||
    defaultAccount.calendars[0];

  return {
    accounts: integrations,
    defaultWriteCalendar: defaultCalendar,
    defaultAccount,
  };
};

export const getGmailAccounts = (gcalData) => {
  return gcalData.accounts.filter((acc) =>
    [
      "https://www.googleapis.com/auth/gmail.readonly",
      "https://www.googleapis.com/auth/gmail.readonly",
    ].every((scope) => acc.gcalServerToken.scope.includes(scope))
  );
};

export const getGcalAccountFromList = (
  accounts,
  writeCalendarId,
  googleAccountId
) => {
  if (googleAccountId)
    return accounts.find((acc) => acc.email === googleAccountId);
  return accounts.find((acc) =>
    acc.gcal.calendars.some(
      (c) =>
        c.id === writeCalendarId && ["owner", "writer"].includes(c.accessRole)
    )
  );
};

// export const getEmail = (accounts, writeCalendarId) => {

//   if (googleAccountId) return accounts.find(acc => acc.email === googleAccountId);
//   return accounts.find(acc => acc.gcal.calendars.some(c => c.id === writeCalendarId));
// }
