import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => (
  <svg
    className={classNames("fill-current", className)}
    width="24"
    height="24"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.33317 19.2114C13.4441 19.8308 15.314 18.9191 17.3818 17.911C19.1527 17.0476 21.0687 16.1134 24.6616 16.0095C25.0297 15.9989 25.3332 15.7015 25.3332 15.3333V3.33331C25.3332 2.96512 25.0297 2.66554 24.6616 2.67618C21.0687 2.78005 19.1527 3.71422 17.3818 4.57762C15.0294 5.7246 12.9331 6.74668 7.50176 5.53142C7.07851 5.43671 6.6665 5.75345 6.6665 6.18716V26C6.6665 26.3682 6.96498 26.6666 7.33317 26.6666H8.6665C9.03469 26.6666 9.33317 26.3682 9.33317 26V19.2114ZM9.33317 8.57142C10.8285 8.77104 12.1269 8.79481 13.3045 8.66965C15.1045 8.47835 16.4637 7.95463 17.6335 7.41372C17.962 7.26185 18.2725 7.11109 18.575 6.9642C19.8359 6.35196 20.9585 5.8069 22.6665 5.52849V13.4819C19.6236 13.8411 17.7012 14.783 16.1867 15.5252C15.8888 15.6711 15.6067 15.8093 15.335 15.9339C14.4521 16.3385 13.6715 16.614 12.6358 16.71C11.8274 16.7849 10.7777 16.7574 9.33317 16.5105V8.57142Z"
    />
  </svg>
);

export default Icon;
