import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => (
  <svg
    className={classNames("fill-current", className)}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 4H10V6H5V20H19V6H14V4H16V2H18V4H21V20C21 21.1046 20.1046 22 19 22H5C3.89543 22 3 21.1046 3 20V4H6V2H8V4ZM11.6538 17.8536C11.8491 18.0488 12.1657 18.0488 12.3609 17.8536L17.6036 12.6109C17.7988 12.4157 17.7988 12.0991 17.6036 11.9038L16.8964 11.1967C16.7012 11.0014 16.3846 11.0014 16.1893 11.1967L13.0147 14.3713V2.5C13.0147 2.22386 12.7909 2 12.5147 2H11.5147C11.2386 2 11.0147 2.22386 11.0147 2.5L11.0147 14.3882L11.0074 14.3787L7.82538 11.1967C7.63012 11.0014 7.31353 11.0014 7.11827 11.1967L6.41117 11.9038C6.2159 12.0991 6.2159 12.4156 6.41117 12.6109L11.6538 17.8536Z"
      fill="#040506"
    />
  </svg>
);

export default Icon;
