import { useState } from "react";
import {
  PackageInstanceApi,
  UpdateUsagePricingRequestUsagePricingListInner,
} from "@practice/sdk";
import useSWR from "swr";
import { useDocument } from "swr-firebase";

import { useAuth } from "@contexts/auth";
import { useRequestIdGenerator } from "@hooks/use-request-id-generator";
import { useSDKApi } from "@hooks/use-sdk-api";
import { PackageInstanceType } from "@lib/data/schemas/package-instance";
import { catchErrorSDK } from "@lib/utils/catch-error-client";
import pluralHelper from "@lib/utils/pluralHelper";

import useLogger from "./use-logger";
import useSnackbar from "./use-snackbar";

export default function usePackageInstance(
  coachUserId?: string,
  packageInstanceId?: string | null
) {
  const { data, error, update, deleteDocument } =
    useDocument<PackageInstanceType>(
      coachUserId && packageInstanceId
        ? `users/${coachUserId}/packageInstances/${packageInstanceId}`
        : null,
      {
        listen: true,
      }
    );
  return {
    packageInstance: data,
    loading: !data && !error,
    update,
    deleteDocument,
  };
}

/**
 * Get the preview end of service from a package instance
 * */
export const usePackageInstancePreviewEndOfService = (
  clientId: string,
  packageInstanceId?: string
) => {
  const { oid } = useAuth();
  const packageInstanceApi = useSDKApi(PackageInstanceApi);
  const generateRequestId = useRequestIdGenerator(
    "use-package-instance-preview-end-of-service"
  );

  const swrData = useSWR(
    packageInstanceId
      ? `/organizations/${oid}/clients/${clientId}/package-instances/${packageInstanceId}/end/preview`
      : null,
    async () => {
      if (!oid || !packageInstanceId) return;
      return packageInstanceApi.endPackageInstancePreview({
        xRequestId: generateRequestId(),
        organizationId: oid,
        clientId,
        packageInstanceId,
      });
    },
    { dedupingInterval: 600000 }
  );

  return {
    data: swrData.data,
    isLoading: !swrData.data && !swrData.error,
    error: swrData.error,
    mutate: swrData.mutate,
  };
};

/**
 * It completes a UBP package instance
 * */
export const useCompletePackageInstanceUsageBased = (
  clinentId: string,
  packageInstanceId: string
) => {
  const { oid } = useAuth();
  const snackbar = useSnackbar();
  const { logger } = useLogger("use-complete-ubp-package-instance");
  const [isCompleting, setIsCompleting] = useState(false);
  const packageInstanceApi = useSDKApi(PackageInstanceApi);
  const generateRequestId = useRequestIdGenerator(
    "use-complete-ubp-package-instance"
  );

  const completeUBP = async () => {
    if (!oid) return;
    try {
      setIsCompleting(true);
      await packageInstanceApi.endPackageInstance({
        xRequestId: generateRequestId(),
        organizationId: oid,
        clientId: clinentId,
        packageInstanceId,
      });
      snackbar.showMessage("The package instance has been completed");
    } catch (error: any) {
      logger.error({ error }, "Failed to complete the package instance");
      const errorMessage = await catchErrorSDK(
        error,
        "Failed to complete the package instance"
      );
      snackbar.showWarning("Something went wrong!", errorMessage);
    } finally {
      setIsCompleting(false);
    }
  };

  return {
    completeUBP,
    isCompleting,
  };
};

/**
 * Updates the scheduler rates of a package instance. It is used from a
 * usage based package instances
 * */
export const useUpdatePackageInstanceSchedulerRates = (
  clientId: string,
  packageInstanceId: string
) => {
  const { oid } = useAuth();
  const snackbar = useSnackbar();
  const { logger } = useLogger("use-update-package-instance-scheduler-rates");
  const packageInstanceApi = useSDKApi(PackageInstanceApi);
  const generateRequestId = useRequestIdGenerator(
    "use-update-package-instance-scheduler-rates"
  );
  const [isUpdating, setIsUpdating] = useState(false);

  const update = async (
    payload: UpdateUsagePricingRequestUsagePricingListInner[]
  ) => {
    if (!oid) return;
    const debugData = {
      organizationId: oid,
      clientId,
      packageInstanceId,
      payload,
    };
    try {
      const total = payload.length;
      setIsUpdating(true);
      logger.info(debugData, "Updating pricing rates");

      await packageInstanceApi.updateUsagePricing({
        xRequestId: generateRequestId(),
        organizationId: oid,
        clientId,
        packageInstanceId,
        updateUsagePricingRequest: {
          usagePricingList: payload,
        },
      });

      snackbar.showMessage(
        `Pricing ${pluralHelper(total, "rate", false)} updated!`
      );
    } catch (error: any) {
      const defaultMessage =
        "Failed to update package instance scheduler rates";
      logger.error({ ...debugData, error }, defaultMessage);
      const errorMessage = await catchErrorSDK(error, defaultMessage);
      snackbar.showWarning("Something went wrong!", errorMessage);
    } finally {
      setIsUpdating(false);
    }
  };

  return {
    isUpdating: isUpdating,
    update,
  };
};

/**
 * It activates a completed package instance
 * */
export const useActivateCompletedPackageInstance = (
  clinentId: string,
  packageInstanceId: string
) => {
  const { oid } = useAuth();
  const snackbar = useSnackbar();
  const { logger } = useLogger("use-activate-completed-package-instance");
  const [isActivating, setIsActivating] = useState(false);
  const packageInstanceApi = useSDKApi(PackageInstanceApi);
  const generateRequestId = useRequestIdGenerator(
    "use-activate-completed-package-instance"
  );

  const active = async () => {
    if (!oid) return;
    try {
      setIsActivating(true);
      await packageInstanceApi.activateCompletedPackageInstance({
        xRequestId: generateRequestId(),
        organizationId: oid,
        clientId: clinentId,
        packageInstanceId,
      });
      snackbar.showMessage("The package instance has been activated");
    } catch (error: any) {
      logger.error({ error }, "Failed to activate the package instance");
      const errorMessage = await catchErrorSDK(
        error,
        "Failed to activate the package instance"
      );
      snackbar.showWarning("Something went wrong!", errorMessage);
    } finally {
      setIsActivating(false);
    }
  };

  return {
    active,
    isActivating,
  };
};
