import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2H8V4H16V2H18V4H21V8.5C21 8.77614 20.7761 9 20.5 9H19.5C19.2239 9 19 8.77614 19 8.5V6H5V20H7.5C7.77614 20 8 20.2239 8 20.5V21.5C8 21.7761 7.77614 22 7.5 22H5C3.89543 22 3 21.1046 3 20V4H6V2ZM21.0536 16.3891C21.2488 16.5844 21.2488 16.9009 21.0536 17.0962L16.8109 21.3388C16.6157 21.5341 16.2991 21.5341 16.1038 21.3388L15.3967 20.6317C15.2014 20.4365 15.2014 20.1199 15.3967 19.9246L17.5787 17.7427L9.5 17.7497C9.22386 17.7497 9 17.5188 9 17.2426V16.2426C9 15.9665 9.21676 15.7453 9.4929 15.7453L17.5787 15.7427L15.3967 13.5607C15.2015 13.3654 15.2015 13.0488 15.3967 12.8536L16.1038 12.1465C16.2991 11.9512 16.6157 11.9512 16.8109 12.1465L21.0536 16.3891Z"
      />
    </svg>
  );
};

export default Icon;
