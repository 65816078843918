import { useMemo } from "react";
import { DateTime } from "luxon";

const favoriteTimezones = [
  "America/Toronto",
  "America/New_York",
  "America/Los_Angeles",
  "America/Chicago",
  "America/Denver",
  "Europe/London",
  "America/Vancouver",
  "Africa/Johannesburg",
  "America/Phoenix",
  "Europe/Amsterdam",
  "Australia/Sydney",
];

export const getTimezoneLabel = (timeZone: string) => {
  const now = DateTime.now().setZone(timeZone);

  const sign = now.offset >= 0 ? "+" : "-";

  return {
    short: `GMT${sign}${Math.abs(now.offset / 60)}`,
    long: now.offsetNameLong,
    offset: now.offset,
  };
};

export default function useTimezones() {
  const timezoneList = useMemo(() => {
    const mapFunctions = (timezone: string) => {
      const { short, long, offset } = getTimezoneLabel(timezone);
      return {
        label: `(${short}) - ${timezone.replace(/_/g, " ")}`,
        searchKey: `(${short}) ${timezone.replace(/_/g, " ")} (${long})`,
        value: timezone,
        offset,
      };
    };
    // For some reason Intl.supportedValuesOf("timeZone") is not working in
    // some browsers, and it return as undefined. Adding the empty array as a
    // fallback, will at least show the favorite timezones.
    const nativeTimezones = Intl?.supportedValuesOf?.("timeZone") || [];

    const allTimezones = nativeTimezones
      .filter((timezone) => !favoriteTimezones.includes(timezone))
      .map(mapFunctions)
      .sort((a, b) => a.offset - b.offset);
    const preferred = favoriteTimezones.map(mapFunctions);
    return [...preferred, ...allTimezones];
  }, []);

  return {
    timeZones: timezoneList,
  };
}
