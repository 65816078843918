import { getSchemaDefinition } from "api-services/common";
import { z } from "zod";

import { AppointmentSchema } from "@lib/data/schemas/appointment";

export const ContactAppointmentSchema = AppointmentSchema.extend({
  eventData: z.any().optional(),
  locationType: z.any().optional(),
});

export const getContactAppointments = getSchemaDefinition(
  "/api/v1/organizations/{orgId}/contacts/{contactId}/appointments",
  "get",
  {
    path: z.object({
      orgId: z.string(),
      contactId: z.string(),
    }),
    query: z.object({
      contactEmail: z.string().optional(),
    }),
  },
  {
    description: "Get all appointments for a specific contact",
    tags: ["contacts", "appointments"],
  },
  z.object({
    // @TODO: We need to make the database consistent from clients. We had to change it in order to improve the performance (WEB-8426)
    //        We should roll back this code one day when we normalize the data.
    data: z.array(ContactAppointmentSchema),
  })
);
