import { z } from "zod";

// @TODO: add missing fields
export const GcalEventSchema = z
  .object({
    id: z.string(),
    summary: z.string().optional(),
    hangoutLink: z.string().optional(),
    location: z.string().optional(),
    attendees: z
      .array(
        z.object({
          email: z.string(),
          responseStatus: z.enum([
            "accepted",
            "tentative",
            "declined",
            "needsAction",
            "shared",
          ]),
        })
      )
      .optional(),
    recurringEventId: z.string().optional(),
    extendedProperties: z.any().optional(),
    organizer: z
      .object({
        email: z.string(),
      })
      .optional(),
    creator: z
      .object({
        email: z.string(),
      })
      .optional(),
  })
  .passthrough();
