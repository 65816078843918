import { useCollection } from "swr-firebase";

import { useAuth } from "@contexts/auth";
import { OutcomeType } from "@lib/data/schemas/outcome";

export default function useOutcomes(outcomeId?: string) {
  const { oid } = useAuth();
  const { data: outcomes, loading } = useCollection<OutcomeType>(
    oid ? `/users/${oid}/outcomes` : null,
    {
      where: ["status", "==", "active"],
      orderBy: ["order", "asc"],
      listen: true,
    }
  );

  // when we have custom outcomes we will sort by category & order here

  const outcome = outcomes?.find(({ id }) => id === outcomeId);
  const getOutcome = (outcomeId: string) =>
    outcomes?.find(({ id }) => id === outcomeId);

  return { outcomes, outcome, getOutcome, isLoading: loading };
}
