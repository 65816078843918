import React, { FC } from "react";
import classNames from "classnames";

import PlusIcon from "./Icons/PlusIcon";

const DefaultButton = ({ onClick, colors, isLoading }) => (
  <button
    className={`w-full p-3 rounded-lg styles.isPrimary styles.actionButton inline-flex items-center justify-center ${colors} disabled:cursor-not-allowed transition ease-in-out duration-150`}
    onClick={onClick}
  >
    <div className="flex flex-row">
      {isLoading ? (
        <img
          src={"/images/spinner_web_96px_transparent.gif"}
          width="24"
          height="24"
          alt="Loading..."
          className="mx-auto"
        />
      ) : (
        <PlusIcon />
      )}
    </div>
  </button>
);

type EmptyListVariantTypes = "default" | "blue" | "transparent";

interface EmptyListProps {
  icon?: React.ReactNode;
  description: string;
  buttonAction?: () => void;
  variant?: EmptyListVariantTypes;
  Button?: FC<any>;
  className?: string;
  isLoadingButton?: boolean;
  hideButton?: boolean;
}

const classNamesByVariant: Record<EmptyListVariantTypes, string> = {
  blue: "bg-blue-900/50 rounded-md text-sm",
  transparent: "text-sm",
  default: "border-dashed border border-grey-900 rounded-lg",
};

const EmptyList: FC<EmptyListProps> = ({
  icon,
  description,
  buttonAction,
  variant = "default",
  Button = DefaultButton,
  className,
  isLoadingButton = false,
  hideButton = false,
}) => {
  const colors =
    "text-black-ink disabled:text-grey-800 bg-grey-950 hover:bg-action-900 focus:outline-none active:bg-action-700 disabled:bg-grey-950";
  const classNameVariant = classNamesByVariant[variant];
  const isDefault = variant === "default";

  return (
    <div
      className={classNames(
        "items-center flex flex-row px-4",
        hideButton ? "py-7" : "py-4",
        classNameVariant,
        className
      )}
    >
      {icon && (
        <div
          className={classNames(
            "ml-2 mr-4 items-center justify-center hidden sm:flex",
            isDefault ? "text-grey-500" : "text-black-ink"
          )}
        >
          {icon}
        </div>
      )}
      <div
        className={classNames(
          "flex-1 mr-4 items-center",
          isDefault ? "text-grey-500" : "text-black-ink"
        )}
      >
        <p>{description}</p>
      </div>
      {!hideButton && buttonAction && (
        <div>
          <Button
            onClick={() => buttonAction()}
            isLoading={isLoadingButton}
            colors={colors}
          />
        </div>
      )}
    </div>
  );
};

export default EmptyList;
