import { useCallback, useMemo } from "react";
import { useCollection } from "swr-firebase";

import { useAuth } from "@contexts/auth";
import { PackageType } from "@lib/data/schemas/packages";

import { useSchedulers } from "./data/schedulers";
import useAccessType from "./use-access-type";

type UsePackagesType = () => {
  packages?: PackageType[];
  loading: boolean;
  getPackage: (packageId: string) => PackageType | undefined;
};

export const usePackages: UsePackagesType = () => {
  const { accessType } = useAccessType();
  const { packages: allPackages, loading: loadingPackages } = useOrgPackages();
  const { data: schedulers, loading: loadingSchedulers } = useSchedulers();

  const packages = useMemo(() => {
    if (!accessType || !allPackages || !schedulers) return;

    if (accessType === "full") return allPackages;

    const schedulerIds = schedulers.map(({ id }) => id);

    return allPackages.filter(({ items }) => {
      return items.some(({ schedulerId }) =>
        schedulerIds.includes(schedulerId)
      );
    });
  }, [allPackages, schedulers, accessType]);

  const getPackage = useCallback(
    (packageId: string) => packages?.find(({ id }) => id === packageId),
    [packages]
  );

  return {
    packages,
    loading: loadingPackages || loadingSchedulers,
    getPackage,
  };
};

export const useOrgPackages: UsePackagesType = () => {
  const { oid } = useAuth();

  const { data: packages, loading } = useCollection<PackageType>(
    oid ? `/users/${oid}/packages` : null,
    {
      where: ["status", "in", ["active", "inactive"]],
      listen: true,
    }
  );

  const getPackage = useCallback(
    (packageId: string) => packages?.find(({ id }) => id === packageId),
    [packages]
  );

  return {
    packages: (packages as PackageType[]) || undefined,
    loading,
    getPackage,
  };
};
